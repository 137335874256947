/**
 * Markets overviews functionality.
 *
 * @module feature/Markets
 */
import 'bootstrap/js/dist/collapse.js';
import FlexColumns from 'FlexColumns';
import lodash from 'lodash';

/**
 * Column and Collapse functionality for markets lists.
 *
 * @requires lodash
 * @requires bootstrap/collapse
 * @requires module:project/Common.FlexColumns
 * @memberof module:feature/Markets
 * @version 1.0.0
 * @author Rocco Janse, rocco.janse@valtech.nl
 */
class Markets {
    /**
     * Initializes DOM element, columns and collapsibles.
     * @param {jQueryElement} element DOM element to upgrade.
     */
    constructor(element) {
        this.$element = $(element);
        this.$flexColumns = this.$element.find('.js-flex-columns');
        this.columns = [];
        this.$collapseParents = this.$element.find('.collapsible');
        this.collapsibles = [];
    }

    /**
     * Creates flex columns and collapsibles. Handles resize events.
     */
    init() {
        // init flex columns
        this.$flexColumns.each((i, el) => {
            let flexColumns = new FlexColumns(el);
            flexColumns.init();
            this.columns.push({ columns: flexColumns });
        });

        // init collapsibles
        this.initCollapsibles();

        // and on window resize
        $(window).on(
            'resize.markets',
            lodash.debounce(() => {
                this.initCollapsibles();
            }, 200)
        );
    }

    /**
     * Makes collapsibles on mobile viewsports
     * and disposes them on wider viewports.
     */
    initCollapsibles() {
        if (viewport.is('<md')) {
            this.collapsibles = [];
            this.$collapseParents.each((i, parent) => {
                const $parent = $(parent);
                this.collapsibles.push({
                    $trigger: $parent.find('.collapse-trigger'),
                    $collapsible: $parent.find('.collapse').collapse(),
                });

                this.collapsibles[i].$collapsible.on('show.bs.collapse', () => {
                    this.collapsibles[i].$trigger.addClass('open');
                });
                this.collapsibles[i].$collapsible.on('hidden.bs.collapse', () => {
                    this.collapsibles[i].$trigger.removeClass('open');
                });

                this.collapsibles[i].$trigger.on('click.collapsible', () => {
                    this.collapsibles[i].$collapsible.collapse('toggle');
                    this.columns[i].columns.updateComponentHeight();
                });
            });
        } else {
            for (let i = 0; i < this.collapsibles.length; i++) {
                this.collapsibles[i].$trigger.off('click.collapsible');
                this.collapsibles[i].$collapsible.collapse('dispose').addClass('show');
                this.columns[i].columns.updateComponentHeight();
            }
            this.collapsibles = [];
        }
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: Markets,
    classAsString: 'Markets',
    cssClass: 'js-markets',
});

export default Markets;
