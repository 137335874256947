import lodash from 'lodash';

/**
 * Flexbox columns wrapper to replace css columns.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class FlexColumns {

    constructor(element) {
        this.$element = $(element);
        this.$items = this.$element.find('li');
        this.totalItems = 0;
        this.numberOfColumns = {};
    }

    init() {

        // set columns
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
        for(var i = 0; i < breakpoints.length; i++) {
            if (typeof this.$element.attr('data-flex-'+ breakpoints[i] + '-columns') !== 'undefined') {
                this.numberOfColumns[breakpoints[i]] = parseInt(this.$element.attr('data-flex-'+ breakpoints[i] + '-columns'));
            } else if ((i-1) >= 0 && typeof this.$element.attr('data-flex-'+ breakpoints[i-1] + '-columns') !== 'undefined') {
                this.numberOfColumns[breakpoints[i]] = parseInt(this.$element.attr('data-flex-'+ breakpoints[i-1] + '-columns'));
            } else {
                this.numberOfColumns[breakpoints[i]] = 1;
            }
        }

        // update component
        this.updateComponentHeight();

        // and on window resize
        $(window).on('resize.flexcolumns', lodash.debounce(() => {
            this.updateComponentHeight();
        }, 200));
    }

    /**
     * Resizes the component based on number of colums for current breakpoint
     * and number of items per column.
     */
    updateComponentHeight() {

        // get items
        this.$items = this.$element.find('li');

        // set total items
        this.totalItems = this.$items.length;

        // items per column
        this.itemsPerColumn = Math.ceil(this.totalItems/this.numberOfColumns[viewport.current()]);

        // resize
        this.getMaxHeightFromCollection().then((maxHeight) => {
            if (maxHeight !== 'auto') {
                this.setComponentHeight(this.itemsPerColumn*maxHeight);
            } else {
                this.setComponentHeight(maxHeight);
            }
        });
    }

    /**
     * Updates component height.
     * @param {number} height
     */
    setComponentHeight(height) {
        let outer = 0;
        outer += parseInt(this.$element.css('border-top-width'));
        outer += parseInt(this.$element.css('padding-top'));
        outer += parseInt(this.$element.css('border-bottom-width'));
        outer += parseInt(this.$element.css('padding-bottom'));
        this.$element.css('height', height + outer);
    }

    /**
     * Returns max height of items within the items collection.
     * @returns {number} maxHeight of items.
     */
    getMaxHeightFromCollection() {
        let max = 0;
        const promise = new Promise((resolve) => {
            for(let i = 0; i < this.$items.length; i++) {
                let height = $(this.$items[i]).outerHeight();
                if (height > max) {
                    max = height;
                }
                if (i === this.$items.length - 1) {
                    if (max === 0) {
                        max = 'auto';
                    }
                    resolve(max);
                }
            }
        });
        return promise;
    }
}

export default FlexColumns;